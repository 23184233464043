
export const state = () => ({
    UnReadCount: 0,
    AccountNoticeList: [],
    AccountNoticeSetting: { Loading: true, PageSetting: { total: 1, page: 1, pageSize: 5 } },
})

export const getters = {
    UnRead: state => {
        let Data = state.AccountNoticeList.filter(function(item, index, array){ return item.read === '0'; });
        return !Data ? 0 : Data.length
    },
    //儲值檢查有無銀行卡(支付)
    DepositCheckBankCard: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('DEPOSIT_CHECK_BANK_CARD') >= 0)
            return rootState.WebSetting.DEPOSIT_CHECK_BANK_CARD != '0';
        else
            return true;
    },
    //儲值檢查有無身分驗證
    DepositCheckIdentity: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('DEPOSIT_CHECK_IDENTITY') >= 0)
            return rootState.WebSetting.DEPOSIT_CHECK_IDENTITY == '1';
        else
            return false;
    },
    //託售檢查有無身分驗證
    WithdrawCheckIdentity: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('WITHDRAW_CHECK_IDENTITY') >= 0)
            return rootState.WebSetting.WITHDRAW_CHECK_IDENTITY == '1';
        else
            return false;
    },
    //是否為單一錢包
    SeamlessWallet: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SEAMLESS_WALLET') >= 0)
            return rootState.WebSetting.SEAMLESS_WALLET == '1';
        else
            return false;
    },
    //是否開啟身分驗證
    ShowAccountCard: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_ACCOUNT_CARD') >= 0)
            return rootState.WebSetting.SHOW_ACCOUNT_CARD == '1';
        else
            return false;
    },
    //是否開啟轉帳頁面
    ShowTransferModal: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SEAMLESS_WALLET') >= 0)
            if (rootState.WebSetting.SEAMLESS_WALLET == '1')
                return false;

        if (rootState.auth.loggedIn)
            if (rootState.auth.user.point_model != 'manual')
                return false;

        return true;
    },
    //是否顯示App下載
    ShowDownloadApp: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_DOWNLOAD_APP') >= 0)
            return rootState.WebSetting.SHOW_DOWNLOAD_APP == '1';
        else
            return false;
    },
    //是否顯示Apk下載
    ShowDownloadApk: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_DOWNLOAD_APK') >= 0)
            return rootState.WebSetting.SHOW_DOWNLOAD_APK == '1';
        else
            return false;
    },
    //是否顯示儲值優惠
    ShowDepositDiscount: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_DEPOSIT_DISCOUNT') >= 0)
            return rootState.WebSetting.SHOW_DEPOSIT_DISCOUNT == '1';
        else
            return false;
    },
    //是否顯示虛擬幣
    ShowVittualCurrency: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_VIRTUAL_CURRENCY') >= 0)
            return rootState.WebSetting.SHOW_VIRTUAL_CURRENCY == '1';
        else
            return false;
    },
    //是否顯示自動轉帳按鈕
    ShowAutoPoint: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_AUTO_POINT') >= 0)
            return rootState.WebSetting.SHOW_AUTO_POINT != '0';
        else
            return true;
    },
    //是否顯示分享賺錢
    ShowShare: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_SHARE') >= 0)
            return rootState.WebSetting.SHOW_SHARE == '1';
        else
            return false;
    },
    //託售密碼功能
    ShowWithdrawCode: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('WITHDRAW_SHOW_CODE') >= 0)
            return rootState.WebSetting.WITHDRAW_SHOW_CODE == '1';
        else
            return false;
    },
    //托售頁面的注意事項是否使用彈窗
    PopUpWithdarawPercaution: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('WITHDRAW_PRECAUTIONS_DISPLAY_POPUP') >= 0)
            return rootState.WebSetting.WITHDRAW_PRECAUTIONS_DISPLAY_POPUP != '0';
        else
            return true;
    },
    ShowAgentSignCaptchas: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('AGRNT_SIGN_CAPTCHAS') >= 0)
            return rootState.WebSetting.AGRNT_SIGN_CAPTCHAS != '0';
        else
            return true;
    },
    ShowAgentSignMobileCaptchas: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('AGRNT_SIGN_MOBILE_CAPTCHAS') >= 0)
            return rootState.WebSetting.AGRNT_SIGN_MOBILE_CAPTCHAS == '1';
        else
            return false;
    },
    ShowLineLogin: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('LOGIN_LINE_SWITCH') >= 0)
            return rootState.WebSetting.LOGIN_LINE_SWITCH == '1';
        else
            return false;
    },
    ShowAccountTotalBet: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_ACCOUNT_TOTAL_BET') >= 0)
            return rootState.WebSetting.SHOW_ACCOUNT_TOTAL_BET == '1';
        else
            return false;
    },
    BankCardCheckImage: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('BANK_CARD_CHECK_IMAGE') >= 0)
            return rootState.WebSetting.BANK_CARD_CHECK_IMAGE != '0';
        else
            return true;
    },
    CancelDepositOrder: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('DEPOSIT_CANCEL_ORDER') >= 0)
            return rootState.WebSetting.DEPOSIT_CANCEL_ORDER == '1';
        else
            return false;
    },
    ImagesDomain: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('ImagesDomain') >= 0)
            return rootState.WebSetting.ImagesDomain;
        else
            return '';
    },
    ImportCheckMobile: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('IMPORT_CHECK_MOBILE') >= 0)
            return rootState.WebSetting.IMPORT_CHECK_MOBILE == '1';
        else
            return false;
    },
    ShowLoginCaptcha: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_LOGIN_CAPTCHA') >= 0)
            return rootState.WebSetting.SHOW_LOGIN_CAPTCHA != '0';
        else
            return true;
    },
    WithdrawShowAudit: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('WITHDRAW_SHOW_AUDIT') >= 0)
            return rootState.WebSetting.WITHDRAW_SHOW_AUDIT != '0';
        else
            return true;
    },
    ShowAnnouncenentPopup: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('SHOW_ANNOUNCEMENT_POPUP') >= 0)
            return rootState.WebSetting.SHOW_ANNOUNCEMENT_POPUP == '1';
        else
            return false;
    },
    IdentityCheckImage: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('IDENTITY_CHECK_IMAGE') >= 0)
            return rootState.WebSetting.IDENTITY_CHECK_IMAGE != '0';
        else
            return true;
    },
    IdentityImagePositiveTip: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('IDENTITY_IMAGE_POSITIVE') < 0)
            return null;
        if (rootState.WebSetting.IDENTITY_IMAGE_POSITIVE == '')
            return null;

        return rootState.WebSetting.IDENTITY_IMAGE_POSITIVE;
    },
    IdentityImageNegativeTip: (state, getters, rootState, rootGetters) => {
        if (Object.keys(rootState.WebSetting).indexOf('IDENTITY_IMAGE_NEGATIVE') < 0)
            return null;
        if (rootState.WebSetting.IDENTITY_IMAGE_NEGATIVE == '')
            return null;

        return rootState.WebSetting.IDENTITY_IMAGE_NEGATIVE;
    },
    FestivalClass: (state, getters, rootState, rootGetters) => {
        return {
            'moon': Object.keys(rootState.WebSetting).indexOf('FESTIVAL_MOON') < 0 ? false : rootState.WebSetting.FESTIVAL_MOON,
            'xmas': Object.keys(rootState.WebSetting).indexOf('FESTIVAL_XMAS') < 0 ? false : rootState.WebSetting.FESTIVAL_XMAS,
            'duanwu': true,//Object.keys(rootState.WebSetting).indexOf('FESTIVAL_DRAGON') < 0 ? false : rootState.WebSetting.FESTIVAL_DRAGON,
            'newyear': Object.keys(rootState.WebSetting).indexOf('FESTIVAL_NEWYEAR') < 0 ? false : rootState.WebSetting.FESTIVAL_NEWYEAR,
            'FIFA': Object.keys(rootState.WebSetting).indexOf('FESTIVAL_FIFA') < 0 ? false : rootState.WebSetting.FESTIVAL_FIFA,
        }
    },


    //Apk下載網址
    GetDownloadApkUrl: (state, getters, rootState, rootGetters) => {
        let HasApkTime = Object.keys(rootState.WebSetting).indexOf('SHOW_DOWNLOAD_APK_TIME') >= 0;
        return '/download/APK' + (HasApkTime ? '?' + rootState.WebSetting.SHOW_DOWNLOAD_APK_TIME : '');
    },
}

export const mutations = {
    setAccountNoticeList: (state, data) => {
        state.UnReadCount = data.Count
        state.AccountNoticeList = data.Pdata.data
        state.AccountNoticeSetting.PageSetting.page = data.Pdata.current_page
        state.AccountNoticeSetting.PageSetting.total = data.Pdata.total
        state.AccountNoticeSetting.PageSetting.pageSize = data.Pdata.per_page
    },
    setUnReadCount: (state, data) => {
        state.UnReadCount = data
    },
    setLoading: (state, data) => {
        switch (data.type) {
            case "AccountNotice":
                state.AccountNoticeSetting.Loading = data.value
                break;
        }
    },
    setCurrent: (state, data) => {
        switch (data.type) {
            case "AccountNotice":
                state.AccountNoticeSetting.PageSetting.page = data.value
                break;
        }
    },
}

export const actions = {
    async getAccountNoticeList({ state, dispatch, commit }, data) {
        commit('setLoading', { type: 'AccountNotice', value: true});
        let params = state.AccountNoticeSetting.PageSetting
        const response = await this.$axios.get('/api/Notice', { params } );
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setAccountNoticeList', response.data);
            commit('setLoading', { type: 'AccountNotice', value: false});
            return res
        })
    },
    async getNureadNotice({ state, dispatch, commit }, data) {
        const response = await this.$axios.post('/api/GetNureadNotice', {} );
        return dispatch('_responseStatus', response, { root: true }).then((res) => {
            if (res) commit('setUnReadCount', response.data);
            return res
        })
    },

}
